@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?7f6t5c');
  src: url('fonts/icomoon.eot?7f6t5c#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?7f6t5c') format('truetype'),
    url('fonts/icomoon.woff?7f6t5c') format('woff'),
    url('fonts/icomoon.svg?7f6t5c#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-accelerated-onboarding-module:before {
  content: '\e966';
}
.icon-shoe-recommendation-module:before {
  content: '\e965';
}
.icon-notification:before {
  content: '\e964';
}
.icon-high-data-completeness:before {
  content: '\e960';
  color: #1dad77;
}
.icon-medium-data-completeness .path1:before {
  content: '\e94b';
  color: rgb(242, 60, 60);
}
.icon-medium-data-completeness .path2:before {
  content: '\e95e';
  margin-left: -1em;
  color: rgb(242, 60, 60);
}
.icon-medium-data-completeness .path3:before {
  content: '\e95f';
  margin-left: -1em;
  color: rgb(230, 230, 231);
}
.icon-low-data-completeness .path1:before {
  content: '\e961';
  color: rgb(230, 230, 231);
}
.icon-low-data-completeness .path2:before {
  content: '\e962';
  margin-left: -1em;
  color: rgb(242, 60, 60);
}
.icon-low-data-completeness .path3:before {
  content: '\e963';
  margin-left: -1em;
  color: rgb(230, 230, 231);
}
.icon-dashboard:before {
  content: '\e95a';
}
.icon-zoom-out-fullscreen:before {
  content: '\e95b';
}
.icon-zoom-in-fullscreen:before {
  content: '\e95c';
}
.icon-down-arrow:before {
  content: '\e95d';
}
.icon-up-arrow:before {
  content: '\e959';
}
.icon-uppercase:before {
  content: '\e957';
}
.icon-renew-subscription:before {
  content: '\e955';
}
.icon-logout:before {
  content: '\e954';
}
.icon-participants:before {
  content: '\e953';
}
.icon-appearance:before {
  content: '\e94c';
}
.icon-attachment:before {
  content: '\e950';
}
.icon-group:before {
  content: '\e951';
}
.icon-upload-doc:before {
  content: '\e952';
}
.icon-font:before {
  content: '\e94f';
}
.icon-logo:before {
  content: '\e94e';
}
.icon-components:before {
  content: '\e94d';
}
.icon-more:before {
  content: '\e945';
}
.icon-eye-closed:before {
  content: '\e943';
}
.icon-eye:before {
  content: '\e944';
}
.icon-female:before {
  content: '\e940';
}
.icon-male:before {
  content: '\e941';
}
.icon-other:before {
  content: '\e942';
}
.icon-cadence:before {
  content: '\e93d';
}
.icon-footstrike:before {
  content: '\e93e';
}
.icon-location:before {
  content: '\e93b';
}
.icon-print-report:before {
  content: '\e93c';
}
.icon-calendar:before {
  content: '\e937';
}
.icon-duration:before {
  content: '\e938';
}
.icon-email:before {
  content: '\e939';
}
.icon-hourglass:before {
  content: '\e93a';
}
.icon-image:before {
  content: '\e934';
}
.icon-settings:before {
  content: '\e935';
}
.icon-text:before {
  content: '\e936';
}
.icon-save:before {
  content: '\e933';
}
.icon-undo:before {
  content: '\e931';
}
.icon-copy:before {
  content: '\e932';
}
.icon-star:before {
  content: '\e930';
}
.icon-checkmark-circle:before {
  content: '\e92c';
}
.icon-chevron-down:before {
  content: '\e92d';
}
.icon-chevron-forward:before {
  content: '\e92e';
}
.icon-chevron-up:before {
  content: '\e92f';
}
.icon-alert:before {
  content: '\e92b';
}
.icon-arrow-down-dropdown:before {
  content: '\e901';
}
.icon-close:before {
  content: '\e900';
}
.icon-question-circle:before {
  content: '\e92a';
}
.icon-members:before {
  content: '\e928';
}
.icon-add-circle:before {
  content: '\e929';
}
.icon-facebook:before {
  content: '\e920';
}
.icon-instagram:before {
  content: '\e927';
}
.icon-linkedin:before {
  content: '\e926';
}
.icon-twitter:before {
  content: '\e923';
}
.icon-whatsapp:before {
  content: '\e924';
}
.icon-youtube:before {
  content: '\e925';
}
.icon-edit:before {
  content: '\e922';
}
.icon-delete:before {
  content: '\e921';
}
.icon-arrow-down:before {
  content: '\e91e';
}
.icon-filter:before {
  content: '\e91d';
}
.icon-chevron-back:before {
  content: '\e91a';
}
.icon-columns:before {
  content: '\e91b';
}
.icon-search:before {
  content: '\e91c';
}
.icon-at-sign:before {
  content: '\e902';
}
.icon-checkmark:before {
  content: '\e903';
}
.icon-close-circle:before {
  content: '\e919';
}
.icon-csv:before {
  content: '\e904';
}
.icon-download:before {
  content: '\e905';
}
.icon-info:before {
  content: '\e906';
}
.icon-lead-activation-created:before {
  content: '\e907';
}
.icon-lead-activation-removed:before {
  content: '\e908';
}
.icon-lead-activation-updated:before {
  content: '\e909';
}
.icon-outdated:before {
  content: '\e90a';
}
.icon-owner:before {
  content: '\e90b';
}
.icon-power-user-admin:before {
  content: '\e90c';
}
.icon-promotion-created:before {
  content: '\e90d';
}
.icon-promotion-removed:before {
  content: '\e90e';
}
.icon-promotion-updated:before {
  content: '\e90f';
}
.icon-repeat:before {
  content: '\e910';
}
.icon-sales:before {
  content: '\e912';
}
.icon-sign:before {
  content: '\e911';
}
.icon-store:before {
  content: '\e91f';
}
.icon-theme:before {
  content: '\e913';
}
.icon-upload:before {
  content: '\e914';
}
.icon-user-accepted-invite:before {
  content: '\e915';
}
.icon-user-invited:before {
  content: '\e916';
}
.icon-user-removed:before {
  content: '\e917';
}
.icon-user-updated:before {
  content: '\e918';
}
.icon-walking-mode-module:before {
  content: '\e946';
}
.icon-video-recording-module:before {
  content: '\e947';
}
.icon-shoe-management-module:before {
  content: '\e948';
}
.icon-shoe-comparison-module:before {
  content: '\e949';
}
.icon-insole-recommendation-module:before {
  content: '\e94a';
}
.icon-filled-star:before {
  content: '\e956';
}
.icon-add:before {
  content: '\e958';
}
.icon-prospects:before {
  content: '\e93f';
}
.icon-assignment:before {
  content: '\e967';
}
