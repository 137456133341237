/* Stolzl Font */
@font-face {
  font-family: 'Stolzl';
  src: url('./assets/fonts/Stolzl/Stolzl-Book.eot');
  src: url('./assets/fonts/Stolzl/Stolzl-Book.eot') format('embedded-opentype'),
    url('./assets/fonts/Stolzl/Stolzl-Book.woff2') format('woff2'),
    url('./assets/fonts/Stolzl/Stolzl-Book.woff') format('woff'),
    url('./assets/fonts/Stolzl/Stolzl-Book.ttf') format('truetype');
  font-weight: 100 300;
}

@font-face {
  font-family: 'Stolzl';
  src: url('./assets/fonts/Stolzl/Stolzl-Regular.eot');
  src: url('./assets/fonts/Stolzl/Stolzl-Regular.eot')
      format('embedded-opentype'),
    url('./assets/fonts/Stolzl/Stolzl-Regular.woff2') format('woff2'),
    url('./assets/fonts/Stolzl/Stolzl-Regular.woff') format('woff'),
    url('./assets/fonts/Stolzl/Stolzl-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Stolzl';
  src: url('./assets/fonts/Stolzl/Stolzl-Medium.eot');
  src: url('./assets/fonts/Stolzl/Stolzl-Medium.eot')
      format('embedded-opentype'),
    url('./assets/fonts/Stolzl/Stolzl-Medium.woff2') format('woff2'),
    url('./assets/fonts/Stolzl/Stolzl-Medium.woff') format('woff'),
    url('./assets/fonts/Stolzl/Stolzl-Medium.ttf') format('truetype');
  font-weight: 500 600;
}

@font-face {
  font-family: 'Stolzl';
  src: url('./assets/fonts/Stolzl/Stolzl-Bold.eot');
  src: url('./assets/fonts/Stolzl/Stolzl-Bold.eot') format('embedded-opentype'),
    url('./assets/fonts/Stolzl/Stolzl-Bold.woff2') format('woff2'),
    url('./assets/fonts/Stolzl/Stolzl-Bold.woff') format('woff'),
    url('./assets/fonts/Stolzl/Stolzl-Bold.ttf') format('truetype');
  font-weight: 700 900;
}

/* Source Sans Pro */
@font-face {
  font-family: 'Source Sans Pro';
  src: url('./assets/fonts/SourceSansPro/SourceSansPro-ExtraLight.ttf')
    format('truetype');
  font-style: normal;
  font-weight: 100 200;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./assets/fonts/SourceSansPro/SourceSansPro-ExtraLightItalic.ttf')
    format('truetype');
  font-style: italic;
  font-weight: 100 200;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./assets/fonts/SourceSansPro/SourceSansPro-Light.ttf')
    format('truetype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./assets/fonts/SourceSansPro/SourceSansPro-LightItalic.ttf')
    format('truetype');
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./assets/fonts/SourceSansPro/SourceSansPro-Regular.ttf')
    format('truetype');
  font-style: normal;
  font-weight: 400 500;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./assets/fonts/SourceSansPro/SourceSansPro-Italic.ttf')
    format('truetype');
  font-style: italic;
  font-weight: 400 500;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./assets/fonts/SourceSansPro/SourceSansPro-SemiBold.ttf')
    format('truetype');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./assets/fonts/SourceSansPro/SourceSansPro-SemiBoldItalic.ttf')
    format('truetype');
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./assets/fonts/SourceSansPro/SourceSansPro-Bold.ttf')
    format('truetype');
  font-style: normal;
  font-weight: 700 800;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./assets/fonts/SourceSansPro/SourceSansPro-BoldItalic.ttf')
    format('truetype');
  font-style: italic;
  font-weight: 700 800;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./assets/fonts/SourceSansPro/SourceSansPro-Black.ttf')
    format('truetype');
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./assets/fonts/SourceSansPro/SourceSansPro-BlackItalic.ttf')
    format('truetype');
  font-style: italic;
  font-weight: 900;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #fafafa;
  overflow-x: hidden;
  font-family: 'Source Sans Pro', 'Stolzl', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Links */
a {
  text-decoration: none;
}

/* notifications */
.custom-toast {
  font-family: 'Source Sans Pro', 'Stolzl', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  border-radius: 4px;
}

/* Carousel Styles */
.swiper-slide {
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  /* Handle */
  background: #8f9295;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  /* Handle on hover */
  background: #8f9295;
}

::-webkit-scrollbar-track-piece {
  /* not handle on */
  background: #30353b;
}
